import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col } from 'react-bootstrap'
import styles from '../../styles/Home.module.scss';
import logo from '../../../../assets/images/svg/sklad_logo.svg';
import { Link } from 'react-router-dom';

class Header extends Component {
  render() {
    return <Container className={styles.header}>
      <Row>
        <Col xs={2}>
        <Link to="/"><img className={styles.logo} src={logo}/></Link>
        </Col>
      </Row>
    </Container>
  }
}

export default Header;