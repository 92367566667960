import React, { Component } from 'react'
import styles from '../../../styles/Page.module.scss';
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet';

class Privacy extends Component {
  render() {
  return <Container>
    <Helmet>
      <title>Privacy Policy - Sklad</title>
    </Helmet>
  <Row>
    <Col>
    <div className={styles.page}>
      <h1>Privacy Policy</h1>
      <div className={styles.modified}><b>Last modified:</b> November 9, 2023</div>
      <p>Your privacy is of utmost importance to Sklad. This Privacy Policy outlines how we collect, use, disclose, transfer, and store your information. Please take a moment to familiarize yourself with our practices, and don't hesitate to reach out if you have any questions.</p>
      <h2>Data Controller and Service Provider</h2>
      <p>
        The data controller under data protection laws and service provider as per the Telecommunications Act is Nikita Skobelkin, residing in Portugal, with email contact at feedback@skladapp.com.
      </p>

      <h2>Collection and Use of Personal Information</h2>
      <p>
        Personal information refers to data that can be used to uniquely identify or contact a specific individual. You may be asked to provide your personal information whenever you interact with us, such as when you fill out our contact form or subscribe to our newsletter. You are not obligated to provide the information we request, but in many cases, this may mean we cannot offer you our products or services or respond to your inquiries.
      </p>

      <p>Here are examples of the types of personal information that we collect:
      <ul>
        <li>When you visit our website, connect to our services, get in touch with us, use our software, create a Sklad account, or subscribe to our newsletter, we collect various information, including your email address, device information, IP address, and a record of your communications.</li>
        <li>When using our software, we collect additional information such as crash reports, information about the operating system, application version, user language, and whether or not you're logged into your Sklad account.</li>
        <li>We also collect additional information such as app's data in the database, analytics events with values like search terms and quantity of actions. However, this data is not transferred to third parties and is used solely to improve products and services without detailing personal information.</li>
      </ul>
      </p>

      <h2>How we use your personal information</h2>
      <p>
        We collect and process all your personal data in accordance with the relevant data protection regulations, especially the General Data Protection Regulation (GDPR), the laws of Portugal, and the Telecommunications Act. This means that we may process your personal information for the purposes described in this Privacy Policy with your consent, if it is necessary for the performance of a contract to which you are a party or in order to take steps at your request before entering into a contract, if it is necessary for compliance with a legal obligation to which Sklad is subject, or when we have determined it is necessary for the purposes of the legitimate interests pursued by Sklad or by a third party to whom it may be necessary to disclose information.
      </p>

      <p>The personal information we collect enables us to keep you informed about our latest product announcements, software updates, and services. You can opt out of receiving such communications by contacting us at any time. In particular, we only send you our newsletter with your prior consent, and you can opt out of receiving the newsletter at any time by clicking the unsubscribe link included in each newsletter or by contacting us.</p>

      <p>We also use the personal information we collect to help us create, develop, deliver, protect, and improve our products, services, content, and customer communications. We may use your personal information to send important notices, such as communications about changes to our terms, conditions, and policies. As this information is crucial to your interaction with Sklad, you may not opt out of receiving these communications.</p>

      <p>We may also use personal information for internal purposes such as auditing, data analysis, and research to enhance our products, services, and customer communications.</p>

      <h2>Disclosure to Third Parties</h2>
      <p>We only share personal information with the following companies: Google (USA) for hosting and database services, and Stripe (USA) for payment services.</p>

      <p>We work together with these companies to provide the mentioned services. These companies are obligated to protect your information in accordance with data protection law and provide the necessary safeguards if they are outside of the EU. The companies are bound by our instructions and are not allowed to use the shared data for any other purpose. We do not share personal information with any other third parties.</p>

      <h2>Protection of Personal Information</h2>
      <p>We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure, or destruction of data. These include internal reviews of our data collection, storage, and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.</p>

      <p>Inside Sklad, we restrict access to personal information to only those employees who need to know that information to deploy and maintain our services. These individuals are bound by confidentiality agreements and may be subject to discipline, including termination and criminal prosecution if they fail to meet these obligations.</p>

      <p>Whenever you connect to our Sklad service, we use encryption such as Transport Layer Security (TLS) for all information that is being transmitted. However, no method of transmitting or storing data is 100% secure, so we cannot guarantee the security of information you transmit to us.</p>

      <p>Some parts of our website, such as our blog or forums, may allow you to post personal information, such as your name or email address. This information is publicly accessible and can be read, collected, and processed by anyone. So please take care when using these features.</p>

      <h2>Access to Personal Information</h2>
      <p>You are entitled to request information about the personal data stored by us, to have incorrect data corrected, or to request the freezing or deletion of your data. For example, you can request the deletion of your Sklad account and its associated email address and content by going into the settings in any of our applications. You are also entitled to the portability of your personal data. Further, you may object to the use of your data at any time with effect for the future. There are, however, cases where we are not allowed to delete your data in its entirety as a result of legal retention periods. We may also decline requests if they risk the privacy of others, would be extremely impractical, or for which access is not required by law. Your personal information is retained for as long as it is necessary to fulfill the purposes outlined in this Privacy Policy, to enforce applicable Terms of Service, or to comply with our legal obligations.</p>

      <h2>Children</h2>
      <p>We do not knowingly collect personal information from children under 16, or equivalent minimum age in the relevant jurisdiction, unless their parent provided verifiable consent. If we learn that we have collected personal information from a child under 16, or equivalent minimum age in the relevant jurisdiction, without the consent of their parent, we will take steps to delete this information as soon as possible. Parents or guardians can contact us.</p>

      <h2>Third-Party Sites and Services</h2>
      <p>Sklad's website or any of our services may contain links to third-party websites or services. We are not responsible for the information collected by those third parties, and we encourage you to read their privacy policy before submitting any personal information to them.</p>

      <h2>Contact Us</h2>
      <p>If you have questions or concerns about our Privacy Policy practices, you may email us at feedback@skladapp.com.</p>
    </div>
    </Col>
  </Row>
</Container>
  }
}

export default Privacy;