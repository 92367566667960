import React, { Component } from 'react';
import Home from './views/home/view/Home';
import Header from './views/home/view/subviews/Header';
import Footer from './views/footer/view/Footer';
import Privacy from './views/privacy/view/Privacy';
import Terms from './views/terms/view/Terms';
import { YMInitializer } from 'react-yandex-metrika';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <Router>
      <Header />
      <Helmet>
        <title>Inventory App for iOS - Sklad</title>
      </Helmet>
      <YMInitializer accounts={[89257682]} />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
