import React, { Component } from 'react'
import HomeContent from './subviews/HomeContent';
import styles from '../styles/Home.module.scss';

class Home extends Component {
  render() {
    return (
      <div className={styles.home}>
        <HomeContent />
      </div>
    );
  }
}

export default Home;