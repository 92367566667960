import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from '../styles/Footer.module.scss';
import { Link } from "react-router-dom";

class Footer extends Component {
    getCurrentYear() {
        return new Date().getFullYear();
    }

    render() {
      return (
        <footer>
        <Container className={styles.container}>
        <Row>
            <Col className="d-flex justify-content-center">
              <div className={styles['footer-links']}>
                <ul>
                  <li><Link to="/privacy">Privacy Policy</Link></li>
                  <li><Link to="/terms">Terms of Use</Link></li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
            &copy; {this.getCurrentYear()} Sklad. All rights reserved.
            </Col>
          </Row>
        </Container>
      </footer>
      );
    }
  }
  
  export default Footer;